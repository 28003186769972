exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-soul-tsx": () => import("./../../../src/pages/about-soul.tsx" /* webpackChunkName: "component---src-pages-about-soul-tsx" */),
  "component---src-pages-artists-tsx": () => import("./../../../src/pages/artists.tsx" /* webpackChunkName: "component---src-pages-artists-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-footer-tsx": () => import("./../../../src/pages/footer.tsx" /* webpackChunkName: "component---src-pages-footer-tsx" */),
  "component---src-pages-header-tsx": () => import("./../../../src/pages/header.tsx" /* webpackChunkName: "component---src-pages-header-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-us-tsx": () => import("./../../../src/pages/join-us.tsx" /* webpackChunkName: "component---src-pages-join-us-tsx" */),
  "component---src-pages-menu-bar-tsx": () => import("./../../../src/pages/menu-bar.tsx" /* webpackChunkName: "component---src-pages-menu-bar-tsx" */),
  "component---src-pages-why-soul-tsx": () => import("./../../../src/pages/why-soul.tsx" /* webpackChunkName: "component---src-pages-why-soul-tsx" */)
}

